import { Col } from "antd";
import React, { useEffect, useState } from "react";

function FicheTableTva({ entreprise, isDarkMode, appliedTaxes  ,selectedCurrency}) {
  const [taxes,setTaxes] = useState([]);

  useEffect(() => {
    taxesChages()
  }, [selectedCurrency,appliedTaxes]);
  
  const taxesChages = () => {
    const filtredTva = [];
    for (const element of appliedTaxes) {
      var montant = 0;
      var base = 0;
      var name = element.tvaPorucentage;
      for (const i of appliedTaxes) {
        if (element.tvaPorucentage === i.tvaPorucentage)
          montant += Number(i.montantTaxes);
          base += Number(i.base);

      }
      if (!filtredTva.find(e => e.tva === name))
        filtredTva.push({ tva: name, montant, base: base });
    }
    setTaxes(filtredTva);
  }



  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
        <thead>
          <tr>
            <th scope="row">#</th>
            <th scope="col">TVA</th>
            <th scope="col">Base</th>
            <th scope="col">Montant</th>
          </tr>
        </thead>
        <tbody>
          {taxes &&
            taxes.map((taxe,i) => 
              <tr key={i}>
                <th scope="row">{i+1}</th>
                <td>{Number(taxe.tva).toFixed(1)} % </td>
                <td>{Number(taxe.base).toFixed(3)} {selectedCurrency}</td>
                <td>{Number(taxe.montant).toFixed(3)} {selectedCurrency} </td>
              </tr>
              )}
        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableTva;
